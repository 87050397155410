export const AUTH_VERIFY_STATUS = 'AUTH_VERIFY_STATUS';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_VERIFY = 'AUTH_VERIFY';
export const AUTH_FORGOT = 'AUTH_FORGOT';
export const CHANGE_MOBILE = 'CHANGE_MOBILE';
export const SET_ALERT = 'SET_ALERT';
export const SET_USERDATA = 'SET_USERDATA';


