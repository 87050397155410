import url from './../../Development.json';
import Http from '../../security/Http'
import * as action from '../../store/actions'
import ToastMe from '../../components/common/ToastMe';
import { successResponse } from 'components/helpers/response';
// const BaseUrl = process.env.REACT_APP_API_HOST;

const ExersiseService = {

    getData: (data) => {
        return dispatch => (
            new Promise((resolve, reject) => {
                Http.callApi(url.get_item_action)
                    .then(function (res) {
                        return resolve(res);
                    })
                    .catch(function (err) {
                        // handle error
                        let errorData, statusCode
                        if (err.response != undefined) {
                            errorData = err?.response?.data?.errors
                            statusCode = err?.response?.status
                        }
                        return reject({
                            errorData,
                            statusCode
                        });
                    })
            })
        )
    },

    // delete item
    deleteItem: (data) => {
        return dispatch => (
            new Promise((resolve, reject) => {
                Http.callApi(url.delete_item, data)
                    .then(function (res) {
                        console.log(res);
                        return resolve(res);
                    })
                    .catch(function (err) {
                        const statusCode = err.response.status;
                        const data = {
                            errors: err.response.data.errors,
                            statusCode,
                            data: err.response.data
                        };
                        return reject(data);
                    })

            })
        )
    },

    // Get Admin Chats
    getAdminChats: (data) => {
        return dispatch => (
            new Promise((resolve, reject) => {
                Http.callApi(url.get_admin_chats)
                    .then(function (res) {
                        return resolve(res);
                    })
                    .catch(function (err) {
                        // handle error
                        let errorData, statusCode
                        if (err.response != undefined) {
                            errorData = err?.response?.data?.errors
                            statusCode = err?.response?.status
                        }
                        return reject({
                            errorData,
                            statusCode
                        });
                    })
            })
        )
    },

    // Get Chat Messages
    getAdminChatMessages: (chatId) => {
        const data = {
            'conversationId': chatId
        }
        return dispatch => (
            new Promise((resolve, reject) => {
                Http.callApi(url.get_admin_chat_messages, data)
                    .then(function (res) {
                        return resolve(res);
                    })
                    .catch(function (err) {
                        // handle error
                        let errorData, statusCode
                        if (err.response != undefined) {
                            errorData = err?.response?.data?.errors
                            statusCode = err?.response?.status
                        }
                        return reject({
                            errorData,
                            statusCode
                        });
                    })
            })
        )
    },
}

export default ExersiseService;