export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      icon: 'chart-pie',
      to: '/dashboard',
      exact: true,
      active: true
    }
  ]
};

export const variableRoutes = {
  label: "Filters & Options",
  children: [
    {
      name: 'Types',
      icon: "shapes",
      to: '/Item-type',
      active: true
    },
    {
      name: 'Shapes',
      icon: "shapes",
      to: '/Item-shape',
      active: true
    },
    {
      name: 'Patterns',
      icon: "shapes",
      to: '/patterns',
      active: true
    },
  ]
}

export const salesMetadata = {
  label: "Orders",
  children: [
    {
      name: 'Orders',
      icon: "shopping-cart",
      to: '/orders',
      active: true
    },
    {
      name: 'Live Auction Reports',
      icon: 'camera',
      to: '/opal-live-reports',
      active: true
    },
  ]
}

export const appContent = {
  label: "App Content",
  children: [
    {
      name: 'Products',
      icon: "shopping-cart",
      to: '/item-auction',
      active: true
    },
    {
      name: 'Podcasts',
      icon: 'puzzle-piece',
      to: '/podcast',
      active: true
    },
  ]
}

export const userData = {
  label: "Users & Businesses",
  children: [
    {
      name: 'Customers',
      icon: ['fas', 'users'],
      to: '/user',
      active: true
    },
    {
      name: 'Vendors',
      icon: ['fas', 'users'],
      to: '/vendor',
      active: true
    },
  ]
}

export const moderationTools = {
  label: "Moderation Tools",
  children: [
    {
      name: 'In-App Messages',
      icon: 'comments',
      to: '/messages',
      active: true
    },
    {
      name: 'User Reports',
      icon: "question-circle",
      to: '/support',
      active: true
    },
  ]
}

export default [
  dashboardRoutes,
  variableRoutes,
  salesMetadata,
  appContent,
  userData,
  moderationTools,
];
