
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Modal, Row, Tooltip } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import GetImage from '../image/getImage';

const SubCategory = () => {
    const { state } = useLocation();
    const [data, setData] = useState([]);
    const [imgSrc, setImageSrc] = useState("");
    const ImageUrl = (datas) => {
        setImageSrc(datas);
    }

    useEffect(() => {
        if (state && state.data) {
            setData(JSON.parse(state.data));
        }
        ImageUrl();
    }, []);

    return (
        <>
            <div style={{ borderRadius: "0.375rem" }} className='py-4 bg-white mb-3 d-flex align-items-center px-3'>
                <h5 className="hover-actions-trigger mb-0">
                    User Details
                </h5>
            </div>

            <div className='col-lg-8 mx-auto'>
                <Card className='p-4'>
                    <div className='row row-cols-1 g-3'>
                        <div className='col d-flex justify-content-center'>
                            <div className='row'>
                                <div className='col-6 flex-nowrap'>
                                    <div className='user_detail_img_container' >
                                        {data.image?<GetImage key={data._id} im={data.image} ImageData={ImageUrl} />:""}
                                        <img src={imgSrc} alt="user" />
                                        {/* <img src="https://pbs.twimg.com/profile_images/1498641868397191170/6qW2XkuI_400x400.png" alt="user" /> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <hr className='w-100 bg-primary my-2' />
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Name</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.first_name} {data?.last_name}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Email</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.email ?? '-'}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>is_verify</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.is_verify == 1 ? 'Yes' : 'No'}</div>
                            </div>
                        </div>
                        {data?.addresses?.map((item, index) => {
                            return (
                                <>
                                    <div className='col'>
                                        <hr className='w-100 bg-primary my-2' />
                                    </div>
                                    <center><h3> Address {index + 1}</h3></center>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col-4 user_view_label'>Address Line 1</div>
                                            <div className="col-2">:</div>
                                            <div className='col-6 flex-nowrap'>{item.address_line1 ?? '-'}</div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col-4 user_view_label'>Address Line 2</div>
                                            <div className="col-2">:</div>
                                            <div className='col-6 flex-nowrap'>{item.address_line2 ?? '-'}</div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col-4 user_view_label'>Area</div>
                                            <div className="col-2">:</div>
                                            <div className='col-6 flex-nowrap'>{item.area ?? '-'}</div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col-4 user_view_label'>Direction</div>
                                            <div className="col-2">:</div>
                                            <div className='col-6 flex-nowrap'>{item.direction ?? '-'}</div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col-4 user_view_label'>Location Description</div>
                                            <div className="col-2">:</div>
                                            <div className='col-6 flex-nowrap'>{item.location_description ?? '-'}</div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col-4 user_view_label'>Lable</div>
                                            <div className="col-2">:</div>
                                            <div className='col-6 flex-nowrap'>{item.lable ?? '-'}</div>
                                        </div>
                                    </div><div className='col'>
                                        <div className='row'>
                                            <div className='col-4 user_view_label'>Location Name</div>
                                            <div className="col-2">:</div>
                                            <div className='col-6 flex-nowrap'>{item.location_name ?? '-'}</div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </Card>
            </div>
        </>
    );
};

// export default Interest;
const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        accessToken: state.Auth.accessToken,
    }
};
export default connect(mapStateToProps)(SubCategory);
