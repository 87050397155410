import url from './../../Development.json';
import Http from '../../security/Http'
import * as action from '../../store/actions'
import ToastMe from '../../components/common/ToastMe';
import { successResponse } from 'components/helpers/response';
// const BaseUrl = process.env.REACT_APP_API_HOST;

const ExersiseService = {

    getData: (data) => {
        return dispatch => (

            new Promise((resolve, reject) => {
                Http.callApi(url.get_exersise)
                    .then(function (res) {
                        return resolve(res);
                    })
                    .catch(function (err) {
                        // handle error
                        let errorData, statusCode
                        if (err.response != undefined) {
                            errorData = err.response.data.errors
                            statusCode = err.response.status
                        }
                        return reject({
                            errorData,
                            statusCode
                        });
                    })

            })
        )
    },

    getMainCategory: (data) => {
        return dispatch => (

            new Promise((resolve, reject) => {
                Http.callApi(url.get_ex_category)
                    .then(function (res) {
                        return resolve(res);
                    })
                    .catch(function (err) {
                        // handle error
                        let errorData, statusCode
                        if (err.response != undefined) {
                            errorData = err.response.data.errors
                            statusCode = err.response.status
                        }
                        return reject({
                            errorData,
                            statusCode
                        });
                    })

            })
        )
    },


    // add category
    addexersise: (data, mainCategory) => {
        return dispatch => (
            new Promise((resolve, reject) => {

                var urlInfo = ""
                if (data.category_id != "") {
                    urlInfo = url.update_exersise
                } else {
                    urlInfo = url.add_exersise
                }

                var dataInfo = {
                    'parent_id': mainCategory,
                    'title': data.title,
                    'description': data.description,
                    'demo_video': data.demo_video,
                }
                console.log(dataInfo);
                Http.callApi(urlInfo, dataInfo)
                    .then(function (res) {
                        successResponse(res)
                        return resolve(res);
                    })
                    .catch(function (err) {
                        ToastMe(err.response.data.message, 'danger');

                        const statusCode = err.response.status;
                        const data = {
                            errors: err.response.data.errors,
                            statusCode,
                            data: err.response.data
                        };
                        return reject({ data });
                    })

            })
        )
    },


    // delete category
    deleteexersise: (data) => {
        return dispatch => (
            new Promise((resolve, reject) => {
                Http.callApi(url.delete_exersise, data)
                    .then(function (res) {
                        console.log(res);
                        return resolve(res);
                    })
                    .catch(function (err) {
                        const statusCode = err.response.status;
                        const data = {
                            errors: err.response.data.errors,
                            statusCode,
                            data: err.response.data
                        };
                        return reject(data);
                    })

            })
        )

    }
}

export default ExersiseService;