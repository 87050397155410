import Swal from 'sweetalert2';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Modal, Row, Tooltip } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import SubCategoryService from '../../services/subcategory/subcategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'components/common/IconButton';
import { useForm } from "react-hook-form";
import { errorResponse, isError } from 'components/helpers/response';
import { toast } from 'react-toastify';
import itemAuctionService from '../../services/itemAuction/itemAuction';
import { Link, useNavigate } from "react-router-dom";

const SubCategory = () => {
    const dispatch = useDispatch();
    const [dataTableData, setDataTableData] = useState([]);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => {
        reset(
            { keepDirtyValues: true },
            { keepIsValid: true }
        );
        setShow(false)
    };
    const {

        formState: { errors },
        reset
    } = useForm();

    useEffect(() => {
        getData();
        isError(errors);

    }, []);

    const handleShow = (data) => {
        navigate('/item-auction/add');
    };

    const getData = () => {
        dispatch(itemAuctionService.getData())
            .then((res) => {
                setDataTableData(res?.data);
            })
            .catch((errors) => {
                console.log(errors);
            })
    }


    const viewButtonClick = (vendorData) => {
        navigate('/item-auction/view', { state: { data: JSON.stringify(vendorData) } });
    };
    const EditButtonClick = (vendorData) => {
        navigate('/item-auction/update', { state: { data: JSON.stringify(vendorData) } });
    };
    // delete category
    const deleteCategory = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                var data = {
                    item_id: id,
                }
                dispatch(itemAuctionService.deleteItem(data))
                    .then((res) => {
                        toast.success(res.data.message);
                        getData();
                    })
                    .catch((errors) => {
                        console.log(errors);
                    })
            }
        })
    }

    function findFirst(array, callback) {
        for (let i = 0; i < array.length; i++) {
            if (callback(array[i])) {
                return array[i];
            }
        }
        return undefined;
    }



    const columns = [
        {
            accessor: '_id',
            Header: 'No',
            Cell: rowData => {
                return (parseInt(rowData.row.id) + 1)
            }
        },
        {
            accessor: 'name',
            Header: 'Name'
        },
        {
            accessor: 'business',
            Header: 'Business',
            Cell: rowData => {
                const data = rowData.row.original

                return (
                    <>
                        {data?.business[0]?.name ?? '-'}
                    </>
                )
            }
        },
        {
            accessor: 'image',
            Header: 'Image',
            Cell: rowData => {
                const data = rowData.row.original.image;
                console.log(data);
                const image = findFirst(data, item => item.type === "photo")
                return (
                    <>
                        <img src={image.item} className="profile_pic_img" style={{ "height": 80, "width": 80, borderRadius: '12px', objectFit: 'cover' }} />
                    </>
                )
            }
        },
        {
            accessor: 'price',
            Header: 'Price',
            Cell: rowData => {
                const data = rowData.row.original;

                return (
                    <p>${data.price.toFixed(2)} AUD</p>
                )
            }
        },

        {
            accessor: 'createdAt',
            Header: 'Action',
            Cell: rowData => {
                const row = rowData.row.original
                return (
                    <>
                        <div className='t-action-btn'>

                            <button title='View Item' className="btn btn-info"  >
                                {
                                    <FontAwesomeIcon icon={faEye} onClick={() => { viewButtonClick(row) }} />
                                }
                            </button>
                            <button title='Delete Item' className="btn btn-danger" onClick={(e) => deleteCategory(row._id)} >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                            <button title='Edit Item' className="btn btn-info" onClick={() => { EditButtonClick(row) }}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                        </div>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={dataTableData}
                sortable
                pagination
                perPage={10}
            >
                <div style={{ borderRadius: "0.375rem" }} className='py-4 bg-white mb-3 d-flex align-items-center px-3'>
                    <h5 className="hover-actions-trigger mb-0">
                        Products
                    </h5>
                </div>
                <Card className='mb-3'>

                    <Card.Header className="border-bottom border-200">
                        <Row className="flex-between-center align-items-end g-2">
                            <Col xs={8} sm="auto" className="ms-3 mt-2 text-end ps-0">
                                <div id="orders-actions">
                                    <IconButton onClick={handleShow}
                                        icon="plus"
                                        transform="shrink-3"
                                        className='btn btn-primary me-2'
                                    >
                                        <span className="d-none d-sm-inline-block ms-1">Add</span>
                                    </IconButton>
                                </div>
                            </Col>
                            <Col xs="auto" sm={6} lg={4}>
                                <AdvanceTableSearchBox table />
                            </Col>
                        </Row>
                    </Card.Header>
                    <Row className="flex-end-center mb-3">

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Row>
                </Card>

                <div className="mt-3">
                    <AdvanceTableFooter
                        rowCount={dataTableData.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableWrapper>
        </>

    );
};

// export default Interest;
const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        accessToken: state.Auth.accessToken,
    }
};
export default connect(mapStateToProps)(SubCategory);
