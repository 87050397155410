import React, { useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderInfo from './OrderInfo';
import OrderedProducts from './OrderedProducts';
import SoftBadge from 'components/common/SoftBadge';

import Http from 'security/Http';
import url from '../../../../../Development.json';
import { errorResponse } from 'components/helpers/response';

import { useParams } from 'react-router';

const OrderDetails = () => {
  let { id } = useParams();
  const [orderDetails, setOrderDetails] = useState({});
  const [orderDate, setOrderDate] = useState(null);

  useEffect(() => {
    getOrderDetails();
  }, []);

  const getOrderDetails = () => {

    const data = {
      'order_no': id
    }
    Http.callApi(url.get_order_details, data)
      .then((response) => {
        console.log(response);
        setOrderDetails(response.data);
        setOrderDate(new Date(response.data.order_date));
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  return (
    <>
      <PageHeader title={`Order #${orderDetails.order_no}`} titleTag="h5" className="mb-3">
        <p className="fs--1 mt-1">Order Placed on: {orderDate ? orderDate.toLocaleDateString() : ""}</p>
        <div>
          <strong className="me-2">Status: </strong>
          <SoftBadge pill bg="success" className="fs--2">
            {' '}
            Completed
            <FontAwesomeIcon
              icon="check"
              className="ms-1"
              transform="shrink-2"
            />
          </SoftBadge>
        </div>
      </PageHeader>
      <OrderInfo details={orderDetails} />
      <OrderedProducts details={orderDetails} />
    </>
  );
};

export default OrderDetails;
