// import React from 'react';
// import { Link } from 'react-router-dom';
// import Flex from 'components/common/Flex';
// import LoginForm from 'components/authentication/LoginForm';

// const Login = () => (
//   <>
//     <Flex justifyContent="between" alignItems="center" className="mb-2">
//       <h5>Log in</h5>
//       {/* <p className="fs--1 text-600 mb-0">
//         or <Link to="/authentication/simple/register">Create an account</Link>
//       </p> */}
//     </Flex>
//     <LoginForm />
//   </>
// );

// export default Login;


import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';

import AuthCardLayout from 'layouts/AuthCardLayout';

const Login = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          Don't have an account?
          <br />
          <Link
            className="text-white text-decoration-underline"
            to="/authentication/card/register"
          >
            Get started!
          </Link>
        </p>
      }
    >
      <center><h3>Admin Login</h3></center>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;

