import Swal from 'sweetalert2';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Modal, Row, Tooltip } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt,faToggleOff, faToggleOn, faEye } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'components/common/IconButton';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { useForm } from "react-hook-form";
import { errorResponse, isError } from 'components/helpers/response';
import { toast } from 'react-toastify';
import supportService from '../../services/support/support';
import url from './../../Development.json';
import Http from '../../security/Http'
import GetImage from '../image/getImage';
import { Link, useNavigate } from "react-router-dom";
import avatar from '../../assets/img/images.jpeg'

const SubCategory = () => {
    const dispatch = useDispatch();
    const [dataTableData, setDataTableData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        dispatch(supportService.getData())
            .then((res) => {
                setDataTableData(res?.data);
            })
            .catch((errors) => {
                console.log(errors);
            })
    }

    const changeStatusButtonClick = (id) => {
        const obj = {
            id: id,
        };
        Http.callApi(url.post_resolved_support, obj)
            .then(function (res) {  
                getData();
                toast.success(res?.data?.message);
            })
            .catch(function (err) {
                // handle error
                toast.error(err?.data?.message);
            })
    };

    const viewButtonClick = (userData) => {
        navigate('/support/view', { state: { data: JSON.stringify(userData) } });
    };
    const columns = [
        {
            accessor: '_id',
            Header: 'No',
            Cell: rowData => {
                return (parseInt(rowData.row.id) + 1)
            }
        },

        {
            accessor: 'email',
            Header: 'Email'
        },
        {
            accessor: 'name',
            Header: 'Name'
        },
        {
            accessor: 'mobile',
            Header: 'Contact Mobile'
        },
        {
            accessor: 'subject',
            Header: 'Subject'
        },
        {
            accessor: 'is_resolved',
            Header: 'is_resolved',
            Cell: rowData => {
                const data = rowData.row.original
                return (
                    <>
                        <span className={`btn-sm   ${data.is_resolved === 1 ? "btn-success" : "btn-danger"}`}>
                            {
                                data.is_resolved === 0 ? "Active" : "Resolved"
                            }
                        </span>
                    </>
                )
            }
        },
        {
            accessor: 'createdAt',
            Header: 'Action',
            Cell: rowData => {
                const row = rowData.row.original
                return (
                    <>
                        <div className='t-action-btn'>
                            <button className="btn btn-primary" title='Change support Status' onClick={(id) => { changeStatusButtonClick(row._id) }} >
                                {
                                    row.is_resolved === 0 ? <FontAwesomeIcon icon={faToggleOff} /> : <FontAwesomeIcon icon={faToggleOn} />
                                }
                            </button>
                            <button className="btn btn-info" title='View Support' >
                                {
                                    <FontAwesomeIcon icon={faEye} onClick={() => { viewButtonClick(row) }} />
                                }
                            </button>
                        </div>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={dataTableData}
                sortable
                pagination
                perPage={10}
            >
                <div style={{ borderRadius: "0.375rem" }} className='py-4 bg-white mb-3 d-flex align-items-center px-3'>
                    <h5 className="hover-actions-trigger mb-0">
                        Contact Support
                    </h5>
                </div>
                <Card className='mb-3'>

                    <Card.Header className="border-bottom border-200">
                        <Row className="flex-between-center align-items-end g-2">
                            <Col xs={8} sm="auto" className="ms-3 mt-2 text-end ps-0">

                            </Col>
                            <Col xs="auto" sm={6} lg={4}>
                                <AdvanceTableSearchBox table />
                            </Col>
                        </Row>
                    </Card.Header>
                    <Row className="flex-end-center mb-3">

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Row>
                </Card>

                <div className="mt-3">
                    <AdvanceTableFooter
                        rowCount={dataTableData.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableWrapper>
        </>
    );
};

// export default Interest;
const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        accessToken: state.Auth.accessToken,
    }
};
export default connect(mapStateToProps)(SubCategory);
