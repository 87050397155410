import React, { useState, useEffect } from 'react';
import SimpleBarReact from 'simplebar-react';
import { Nav, Tab, Card, Col, Row, Image } from 'react-bootstrap';
import MessageTextArea from 'components/app/chat/content/MessageTextArea';
import Flex from 'components/common/Flex';
import { connect, useDispatch } from "react-redux";
import ChatItem from '../../services/itemAuction/itemAuction';

import classNames from 'classnames';

import Avatar from 'components/common/Avatar';
import LastMessage from 'components/app/chat/sidebar/LastMessage';

import FalconLightBox from 'components/common/FalconLightBox';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const ChatMessages = ({ message, status }) => {
    const isLeft = message.receiverType === 0;

    const dateTime = new Date(message.createdAt);
    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString('en-AU', { hour: '2-digit', minute: '2-digit', hour12: true });

    return (
        <Flex className={classNames('p-3', { 'd-block': !isLeft })}>
            {isLeft && <Avatar size="l" className="me-2" src={message.sender.image} />}
            <div
                className={classNames('flex-1', {
                    'd-flex justify-content-end': !isLeft
                })}
            >
                <div
                    className={classNames('w-xxl-75', {
                        'w-100': !isLeft
                    })}
                >
                    <Flex
                        alignItems="center"
                        className={classNames('hover-actions-trigger', {
                            'flex-end-center': !isLeft,
                            'align-items-center': isLeft
                        })}
                    >
                        {/* {!isLeft && <ChatMessageOptions />} */}
                        {message.attachments ? (
                            <div className="chat-message chat-gallery">
                                {message.text && (
                                    <p
                                        className="mb-0"
                                        dangerouslySetInnerHTML={{
                                            __html: message.message ? message.message : message
                                        }}
                                    />
                                )}
                                <FalconLightBoxGallery images={message.attachments}>
                                    {setImgIndex => (
                                        <Row className="mx-n1">
                                            {message.attachments.map((img, index) => {
                                                return (
                                                    <Col
                                                        xs={6}
                                                        md={4}
                                                        className="px-1"
                                                        style={{ minWidth: 50 }}
                                                        key={index}
                                                    >
                                                        <Image
                                                            fluid
                                                            rounded
                                                            className="mb-2 cursor-pointer"
                                                            src={img}
                                                            alt=""
                                                            onClick={() => setImgIndex(index)}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    )}
                                </FalconLightBoxGallery>
                            </div>
                        ) : (
                            <>
                                <div
                                    className={classNames('p-2 rounded-2 chat-message', {
                                        'bg-200': isLeft,
                                        'bg-primary text-white light': !isLeft
                                    })}
                                >
                                    {(message || message.message) && (
                                        <p
                                            className="mb-0"
                                            dangerouslySetInnerHTML={{
                                                __html: message.message ? message.message : message
                                            }}
                                        />
                                    )}
                                    {message.attachment && (
                                        <FalconLightBox image={message.attachment}>
                                            <Image
                                                fluid
                                                rounded
                                                src={message.attachment}
                                                width={150}
                                                alt=""
                                            />
                                        </FalconLightBox>
                                    )}
                                </div>
                            </>
                        )}
                    </Flex>
                    <div
                        className={classNames('text-400 fs--2', {
                            'text-end': !isLeft
                        })}
                    >
                        <span className="font-weight-semi-bold">{message.sender.name}</span>
                        <br />
                        {date} {time}
                        {!isLeft && !!message && !!status && (
                            <FontAwesomeIcon
                                icon={classNames({
                                    check: status === 'seen' || status === 'sent',
                                    'check-double': status === 'delivered'
                                })}
                                className={classNames('ms-2', {
                                    'text-success': status === 'seen'
                                })}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Flex>
    );
}

const ChatUISidebarItem = ({ thread, index }) => {
    return (
        <Nav.Link
            eventKey={index}
            className={`chat-contact hover-actions-trigger p-3 read-message`}
        >
            <Flex>
                <Avatar src={thread.sender.image} size="xl" />
                <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                    <Flex justifyContent="between">
                        <h6 className="mb-0 chat-contact-title">{thread.sender.name}</h6>
                        <span className="message-time fs--2">
                            {' '}
                            {/* {!!lastMessage && lastMessage.time.day}{' '} */}
                        </span>
                    </Flex>
                    <p style={{ fontSize: 14, color: "gray" }}>& {thread.receiver.name}</p>
                </div>
            </Flex>
        </Nav.Link>
    );
}

const ChatUISidebar = ({ threads }) => {
    return (
        <div className={'chat-sidebar'}>
            <div className="contacts-list">
                <SimpleBarReact style={{ height: '100%', minWidth: '65px' }}>
                    <Nav className="border-0">
                        {threads && threads.map((thread, index) => (
                            <ChatUISidebarItem thread={thread} index={index} key={thread.id} />
                        ))}
                    </Nav>
                </SimpleBarReact>
            </div>
        </div>
    );
}


const ChatUIContent = ({ threads, messages }) => {
    return (
        <Tab.Content className="card-chat-content">
            {threads.map((thread, index) => (
                <Tab.Pane key={index} eventKey={index} className="card-chat-pane">
                    <div className="chat-content-body" style={{ display: 'inherit' }}>
                        <SimpleBarReact style={{ height: '100%' }}>
                            <div className="chat-content-scroll-area">
                                <Flex
                                    alignItems="center"
                                    className="position-relative p-3 border-bottom mb-3"
                                >
                                    <Avatar className={`me-3`} size="2xl" src={thread.sender.image} />
                                    <div className="flex-1">
                                        <h6 className="mb-0">
                                            {thread.sender.name} & {thread.receiver.name}
                                        </h6>
                                    </div>
                                </Flex>
                                {messages.map((message, index) => (
                                    <div key={index}>
                                        <ChatMessages
                                            message={message}
                                            senderUserId={message.sender._id}
                                        />
                                    </div>
                                ))}
                            </div>
                        </SimpleBarReact>
                    </div>


                </Tab.Pane>
            ))}
            {/* <MessageTextArea /> */}
        </Tab.Content>
    )
}

const ChatUI = () => {

    const dispatch = useDispatch();

    const [threads, setThreads] = useState([]);
    const [messages, setMessages] = useState([]);
    const [activeThread, setActiveThread] = useState({});

    useEffect(() => {
        getChats();
    }, []);

    const getChats = () => {
        dispatch(ChatItem.getAdminChats())
            .then((res) => {
                console.log("THREAD DATA", res);
                setThreads(res.data);
                setActiveThread(res.data[0]);
            })
            .catch((errors) => {
                console.log(errors);
            })
    }

    const getChatMessages = (threadId) => {
        console.log("THREAD ID", threadId);
        dispatch(ChatItem.getAdminChatMessages(threadId))
            .then((res) => {
                console.log("MESSAGE DATA", res);
                setMessages(res.data);
            })
            .catch((errors) => {
                console.log(errors);
            })
    }

    useEffect(() => {
        getChatMessages(activeThread.mutualChatId);
    }, [activeThread])

    const handleSelect = (e) => {
        console.log("UPDATE ACTIVE THREAD TO: ", e);
        setActiveThread(threads[e]);
    }

    return (
        <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="0"
            onSelect={handleSelect}
        >
            <Card className="card-chat overflow-hidden">
                <Card.Body as={Flex} className="p-0 h-100">
                    <ChatUISidebar threads={threads} />
                    <ChatUIContent threads={threads} messages={messages} />
                </Card.Body>
            </Card>
        </Tab.Container>
    );
}

export default ChatUI;