import React, { useState, useEffect } from 'react'
import { Buffer } from "buffer";

const GetImage = (props) => {
    
    let propsImage = props.im;

    useEffect(() => {
        if (propsImage) {
            urlFetch(propsImage)
        }
    }, [propsImage])

    const urlFetch = (profiledata) => {

         fetch(profiledata.toString(), {
            method: "GET",
            headers: new Headers({
                'authorization': `Bearer ` + localStorage.getItem('accessToken'),
                'Content-Type': 'application/json',
                'env': 'test'
            })
        }).then(response => {
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                const data = `data:${"image/jpeg"};base64,${new Buffer(value).toString('base64')}`;
                                
                                props.ImageData(data);
                                return pump();
                            });
                        }
                    }
                })
            })
    }   
    return(
        <>

        </>
    )

}

export default GetImage;