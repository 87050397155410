import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Modal, Row, Tooltip } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';
import { getSize } from 'helpers/utils';
import { Dropdown } from 'react-bootstrap';
import url from './../../Development.json';
import Http from '../../security/Http'
import { useForm } from "react-hook-form";
import { errorResponse, successResponse, isError } from "../helpers/response";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const AddItem = () => {
    const [files, setFiles] = React.useState([]);
    const [getShape, setGetShape] = useState([]);
    const [getType, setGetType] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [images, setImage] = useState([]);
    const [deleteImgs, setDeleteImgs] = useState([]);
    const [coin, setCoin] = useState(0);
    const navigate = useNavigate();
    const [vendorId, setVendorId] = useState("");

    const { setValue, handleSubmit, register, formState: { errors }, reset } = useForm();

    const getVendorData = () => {
        Http.callApi(url.get_vendor)
            .then((response) => {
                setVendor(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getTypepeData = () => {
        Http.callApi(url.get_item_type)
            .then((response) => {
                setGetType(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getShapeData = () => {
        Http.callApi(url.get_item_shape)
            .then((response) => {
                setGetShape(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const imageUpload = (data) => {
        data.map((item) => {
            let img = new FormData();
            img.append('image', item);

            Http.callApi(url.upload_image, img)
                .then(function (res) {
                    images.push({ img: res.data.image, data: item });
                })
                .catch(function (err) {
                    // handle error
                    console.log(err);
                })
        })
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            imageUpload(acceptedFiles)

            acceptedFiles.map(file =>
                files.push(Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            )
        }
    });

    const addItem = (data) => {
        let img = [];
        if (images.length != 0) {
            images.map((item) => {
                img.push(item.img);
            })
        }
        let imgDel = [];
        if (deleteImgs.length != 0) {
            deleteImgs.map((item) => {
                imgDel.push(item[0].img);
            })
        }
        data['image'] = img;
        data['delete_image'] = imgDel;
        data['type'] = JSON.parse(data['type']);
        data['select_item_type'] = '2';
        data['is_filp_coin'] = (data['is_filp_coin'] == '1') ? true : false;
        data['shape'] = JSON.parse(data['shape']);
        if (img.length == 0) {
            toast.error("image is required");
        } else {
            Http.callApi(url.add_item_action, data)
                .then(function (res) {
                    toast.success("Item Added Successfully!");
                    setTimeout(() => {
                        navigate('/item-auction');
                    }, 2500);
                })
                .catch(function (error) {
                    // handle error
                    if (error.response.data.errors) {
                        let arr = error.response.data.errors;
                        Object.values(arr).map((item) => {
                            toast.error(item);
                        })
                    }
                    if (!error) {
                        toast.error("Please Try Again!");
                    }
                })
        }
    }
    const handleRemove = path => {
        setFiles(files.filter(file => file.path !== path));
        // setDeleteImgs(images.filter(data => data.data.path == path));
        setImage(images.filter(data => data.data.path !== path))
        deleteImgs.push(images.filter(data => data.data.path == path));
    };
    useEffect(() => {
        isError(errors);
    });

    useEffect(() => {
        getShapeData();
        getTypepeData();
        getVendorData();
    }, []);

    return (
        <>
            <div style={{ borderRadius: "0.375rem" }} className='py-4 bg-white mb-3 d-flex align-items-center px-3'>
                <h5 className="hover-actions-trigger mb-0">
                    Add Item
                </h5>
            </div>

            <Form noValidate onSubmit={handleSubmit(addItem)}>
                <div className='row'>
                    <Form.Group className="mb-3 col-lg-4" >
                        <Form.Select
                            name="business_id"
                            id="business_id"
                            defaultValue={vendorId}
                            aria-label="Default select example"
                            onChange={(e) => setVendorId(e.target.value)}
                            {...register("business_id", {
                                required: "Vendor is required"
                            })}
                        >
                            <option value="" disabled selected>
                                Select Vendor
                            </option>
                            {vendor.map((item, index) => {
                                return (
                                    <option key={index} value={item._id}>{item.name}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3 col-lg-4">
                        <Form.Select aria-label="Default select example" name="type" id="type" isInvalid={!!errors.type}
                            {...register('type', {
                                required: true
                            })} >
                            <option value='' disabled selected>Select item type</option>
                            {getType && getType.map((item, index) => {
                                return (
                                    <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-4">
                        <Form.Select aria-label="Default select example" name="shape" id="shape" isInvalid={!!errors.shape}
                            {...register('shape', {
                                required: true
                            })}>
                            <option value='' disabled selected>Select item shape</option>
                            {getShape.map((item, index) => {
                                return (
                                    <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                </div>
                <div className='row'>
                    <Form.Group className="mb-3 col-lg-6" >
                        <Form.Label>Item Name</Form.Label>
                        <Form.Control type="text" name="name" id="name" placeholder="Enter item name" isInvalid={!!errors.name}
                            {...register('name', {
                                required: true
                            })} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-6">
                        <Form.Label>Item Price</Form.Label>
                        <Form.Control type="text" name="price" id="price" placeholder="Enter item price" isInvalid={!!errors.price}
                            {...register('price', {
                                required: true,
                                pattern: {
                                    value: /^\d+(?:\.\d+)?$/,
                                    message: 'Please enter a number',
                                },
                            })} />
                    </Form.Group>
                </div>
                <div className='row'>
                    <Form.Group className="mb-3" >
                        <Form.Label>Item Description</Form.Label>
                        <Form.Control as="textarea" rows={3} id="description" name="description" isInvalid={!!errors.description}
                            {...register('description', { required: true })} />
                    </Form.Group>
                </div>
                <div className='row'>
                    <Form.Group className="mb-3 col-lg-3">
                        <Form.Label>Item Weight</Form.Label>
                        <Form.Control type="text" name="weight" id="weight" placeholder="weight"
                            {...register('weight', {
                                required: true,
                                pattern: {
                                    value: /^\d+(?:\.\d+)?$/,
                                    message: 'Please enter a number',
                                },
                            })} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-3">
                        <Form.Label>Item Width</Form.Label>
                        <Form.Control type="text" name="width" id="width" placeholder="width (mm)"
                            {...register('width', {
                                required: true,
                                pattern: {
                                    value: /^\d+(?:\.\d+)?$/,
                                    message: 'Please enter a number',
                                },
                            })} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-3">
                        <Form.Label>Item length</Form.Label>
                        <Form.Control type="text" name="length" id="length" placeholder="length (mm)"
                            {...register('length', {
                                required: true,
                                pattern: {
                                    value: /^\d+(?:\.\d+)?$/,
                                    message: 'Please enter a number',
                                },
                            })} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-3">
                        <Form.Label>Item Depth</Form.Label>
                        <Form.Control type="text" name="depth" id="depth" placeholder="depth (mm)"
                            {...register('depth', {
                                required: true,
                                pattern: {
                                    value: /^\d+(?:\.\d+)?$/,
                                    message: 'Please enter a number',
                                },
                            })} />
                    </Form.Group>
                </div>
                <div className='row'>
                    <Form.Group className="mb-3 col-lg-4" >
                        <Form.Label>Split the Difference</Form.Label>
                        <Form.Select
                            name="is_filp_coin"
                            id="is_filp_coin"
                            defaultValue={coin}
                            aria-label="Default select example"
                            onChange={(e) => setCoin(e.target.value)}
                        >
                            <option value="1">Allow</option>
                            <option value='0' selected>Disallow</option>
                        </Form.Select>
                    </Form.Group>

                    {coin === "1" ? <><Form.Group className="mb-3 col-lg-4">
                        <Form.Label>Max Price</Form.Label>
                        <Form.Control type="text" name="max_price" id="max_price" defaultValue='0' placeholder="Max price"
                            {...register('max_price', {
                                required: true,
                                pattern: {
                                    value: /^\d+(?:\.\d+)?$/,
                                    message: 'Please enter a number',
                                },
                            })} />
                    </Form.Group>
                        <Form.Group className="mb-3 col-lg-4">
                            <Form.Label>Min Price</Form.Label>
                            <Form.Control type="text" name="min_price" id="min_price" defaultValue='0' placeholder="Min price"
                                {...register('min_price', {
                                    required: true,
                                    pattern: {
                                        value: /^\d+(?:\.\d+)?$/,
                                        message: 'Please enter a number',
                                    },
                                })} />
                        </Form.Group></> : <></>}
                </div>
                <div {...getRootProps({ className: 'dropzone-area py-4' })}>
                    <input {...getInputProps()} />
                    <Flex justifyContent="center">
                        <img src={cloudUpload} alt="" width={25} className="me-2" />
                        <p className="fs-0 mb-0 text-700">Drop your images here</p>
                    </Flex>
                </div>
                <div className='row'>
                    {files.map(file => (
                        <div className="mt-3 col-lg-2">
                            <Flex
                                alignItems="center"
                                className="py-3 border-bottom btn-reveal-trigger"
                                key={file.path}
                            >
                                <img rounded width={90} height={90} src={file.preview} alt={file.path} />
                                <CardDropdown>
                                    <div className="py-2">
                                        <Dropdown.Item className="text-danger" onClick={() => handleRemove(file.path)}>
                                            Remove
                                        </Dropdown.Item>
                                    </div>
                                </CardDropdown>
                            </Flex>
                        </div>
                    ))}
                </div>
                <br></br>
                <Button variant="primary" type="submit">Submit</Button>
            </Form>
        </>
    );
};

// export default Interest;
const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        accessToken: state.Auth.accessToken,
    }
};
export default connect(mapStateToProps)(AddItem);
