import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Modal, Row, Tooltip } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt, faToggleOff, faToggleOn, faEye } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import vendorService from '../../services/vendor/vendor';
import url from './../../Development.json';
import Http from '../../security/Http'
import GetImage from '../image/getImage';
import { Link, useNavigate } from "react-router-dom";
import avatar from '../../assets/img/images.jpeg'

const SubCategory = () => {
    const dispatch = useDispatch();
    const [dataTableData, setDataTableData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        dispatch(vendorService.getData())
            .then((res) => {
                setDataTableData(res?.data);
            })
            .catch((errors) => {
                console.log(errors);
            })
    }

    const changeStatusButtonClick = (id) => {
        const obj = {
            user_id: id,
        };
        Http.callApi(url.post_vendor_filp_coin, obj)
            .then(function (res) {
                getData();
                toast.success(res?.data?.message);
            })
            .catch(function (err) {
                // handle error
                toast.error(err?.data?.message);
            })
    };

    const changeVendorStatus = (id) => {
        console.log("CHANGE VENDOR STATUS");
        const obj = {
            user_id: id,
        };
        Http.callApi(url.post_vendor_active, obj)
            .then(function (res) {
                getData();
                toast.success(res?.data?.message);
            })
            .catch(function (err) {
                // handle error
                toast.error(err?.data?.message);
            })
    };

    const changeVendorGSTStatus = (id) => {
        console.log("Change Vendor GST Status");
        Http.callApi(url.post_vendor_gst_status, { user_id: id })
            .then(function (res) {
                console.log(res);
                getData();
                toast.success(res?.data?.message);
            })
            .catch(function (err) {
                // handle error
                console.log(err);
                toast.error(err?.data?.message);
            });
    }

    const viewButtonClick = (vendorData) => {
        navigate('/vendor/view', { state: { data: JSON.stringify(vendorData) } });
    };

    const columns = [
        {
            accessor: '_id',
            Header: 'No',
            Cell: rowData => {
                return (parseInt(rowData.row.id) + 1)
            }
        },
        {
            accessor: 'email',
            Header: 'Email'
        },
        {
            accessor: 'name',
            Header: 'Name'
        },
        {
            accessor: 'image',
            Header: 'Image',
            Cell: rowData => {
                const data = rowData.row.original

                return (
                    <>
                        {data.image ? <>
                            <img src={data.image} className="profile_pic_img" style={{ "height": 64, "width": 64, "borderRadius": "50px", objectFit: 'cover' }} />
                        </> : <>
                            <img src={avatar} className="profile_pic_img" style={{ "height": 64, "width": 64, "borderRadius": "50px", objectFit: 'cover' }} />
                        </>}
                    </>
                )
            }
        },
        {
            accessor: 'is_streming',
            Header: 'Opal Live',
            Cell: rowData => {
                const data = rowData.row.original
                return (
                    <>
                        <span onClick={() => {
                            changeStatusButtonClick(data._id)
                        }} className={`btn-sm   ${data.is_streming === true ? "btn-success" : "btn-danger"}`}>
                            {
                                data.is_streming === true ? "Opal Live Allowed" : "Opal Live Not Allowed"
                            }
                        </span>
                    </>
                )
            }
        },
        {
            accessor: 'is_verify',
            Header: 'Active on Opal Empire',
            Cell: rowData => {
                const data = rowData.row.original
                return (
                    <>
                        <span onClick={() => { changeVendorStatus(data._id) }} className={`btn-sm   ${data.is_verify === 1 ? "btn-success" : "btn-danger"}`}>
                            {
                                data.is_verify === 1 ? "Vendor Active" : "Vendor Deactive"
                            }
                        </span>
                    </>
                )
            }
        },
        {
            accessor: 'gst_registered',
            Header: 'GST Registered',
            Cell: rowData => {
                const data = rowData.row.original
                return (
                    <>
                        <span onClick={() => { changeVendorGSTStatus(data._id) }} className={`btn-sm   ${data.gst_registered && data.gst_registered !== false ? "btn-success" : "btn-danger"}`}>
                            {
                                data.gst_registered && data.gst_registered !== false ? "GST Registered" : "No GST"
                            }
                        </span>
                    </>
                )
            }
        },
        {
            accessor: 'createdAt',
            Header: 'Action',
            Cell: rowData => {
                const row = rowData.row.original
                return (
                    <>
                        <div className='t-action-btn' >
                            <button className="btn btn-info" title='View Vendor' >
                                {
                                    <FontAwesomeIcon icon={faEye} onClick={() => { viewButtonClick(row) }} />
                                }
                            </button>
                        </div>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={dataTableData}
                sortable
                pagination
                perPage={10}
            >
                <div style={{ borderRadius: "0.375rem" }} className='py-4 bg-white mb-3 d-flex align-items-center px-3'>
                    <h5 className="hover-actions-trigger mb-0">
                        Vendors
                    </h5>
                </div>
                <Card className='mb-3'>

                    <Card.Header className="border-bottom border-200">
                        <Row className="flex-between-center align-items-end g-2">
                            <Col xs={8} sm="auto" className="ms-3 mt-2 text-end ps-0">

                            </Col>
                            <Col xs="auto" sm={6} lg={4}>
                                <AdvanceTableSearchBox table />
                            </Col>
                        </Row>
                    </Card.Header>
                    <Row className="flex-end-center mb-3">

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Row>
                </Card>

                <div className="mt-3">
                    <AdvanceTableFooter
                        rowCount={dataTableData.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableWrapper>
        </>
    );
};

// export default Interest;
const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        accessToken: state.Auth.accessToken,
    }
};
export default connect(mapStateToProps)(SubCategory);
