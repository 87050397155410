import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WeeklySales from './WeeklySales';
import { Row, Col } from 'react-bootstrap';

import BestSellingProducts from './BestSellingProducts';
import { connect, useDispatch } from "react-redux";
import vendorService from '../../../services/vendor/vendor';
import UserService from '../../../services/user/user';
import UserItem from '../../../services/itemAuction/itemAuction';
import Http from '../../../security/Http'
import url from './../../../Development.json';
import TotalOrder from './TotalOrder';
import StatisticsCard from '../saas/stats-cards/StatisticsCard';

import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import StatisticsCards from '../saas/stats-cards/StatisticsCards';


const Dashboard = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState(0);
  const [vendors, setVendors] = useState(0);
  const [orders, setOrders] = useState(0);
  const [items, setItems] = useState(0);
  const [itemsData, setItemsData] = useState([]);

  // Dashboard Analytics
  const [revenue, setRevenue] = useState(0);
  const [averageValue, setAverageValue] = useState(0);
  const [totalRefunds, setTotalRefunds] = useState(0);
  const [activeVisits, setActiveVisits] = useState(0);
  const [cartAbandonment, setCartAbandonment] = useState(0);
  const [orderFrequency, setOrderFrequency] = useState("");

  const statisticsData = [
    {
      title: "Total Revenue",
      value: revenue,
      decimal: 2,
      suffix: '',
      prefix: '$',
      valueClassName: 'text-default',
      badgeBg: 'default',
      badgeText: '',
      link: '#',
      linkText: '',
      image: bg1
    },
    {
      title: "Average Order Value",
      value: averageValue,
      decimal: 2,
      suffix: '',
      prefix: '$',
      valueClassName: 'text-default',
      badgeBg: 'default',
      badgeText: '',
      link: '#',
      linkText: '',
      image: bg2
    },
    {
      title: "Total Refunds",
      value: totalRefunds,
      decimal: 2,
      suffix: '',
      prefix: '$',
      valueClassName: 'text-default',
      badgeBg: 'default',
      badgeText: '',
      link: '#',
      linkText: '',
      image: bg3
    },
    {
      title: "Active Visits",
      value: activeVisits,
      decimal: 0,
      suffix: '',
      prefix: '',
      valueClassName: 'text-default',
      badgeBg: 'default',
      badgeText: '',
      link: '#',
      linkText: '',
      image: bg1
    },
    {
      title: "Cart Abandonment (%)",
      value: cartAbandonment,
      decimal: 2,
      suffix: '%',
      prefix: '',
      valueClassName: 'text-default',
      badgeBg: 'default',
      badgeText: '',
      link: '#',
      linkText: '',
      image: bg2
    },
    {
      title: "Time Between Orders",
      value: orderFrequency,
      decimal: 0,
      suffix: '',
      prefix: '',
      valueClassName: 'text-default',
      badgeBg: 'default',
      badgeText: '',
      link: '#',
      linkText: '',
      image: bg3
    }
  ];

  useEffect(() => {
    getUser();
    getVendor();
    getItem();
    getOrder();

    // Dashboard APIs
    getRevenue();
    getAverageOrderValue();
    getAllTimeRefunds();
    getActiveVisits();
    getCartAbandonmentValue();
    getOrderFrequency();
  }, []);

  const getVendor = () => {
    dispatch(vendorService.getData())
      .then((res) => {
        setVendors(res.data.length);
      })
      .catch((errors) => {
        console.log(errors);
      })
  }

  const getUser = () => {
    dispatch(UserService.getData())
      .then((res) => {
        setUsers(res.data.length);
      })
      .catch((errors) => {
        console.log(errors);
      })
  }

  const getItem = () => {
    dispatch(UserItem.getData())
      .then((res) => {
        setItems(res.data.length);
        setItemsData(res.data);
      })
      .catch((errors) => {
        console.log(errors);
      })
  }

  const getOrder = () => {
    Http.callApi(url.get_order_count)
      .then(function (res) {
        setOrders(res.data.orderCount);
      })
      .catch(function (err) {
        console.log(err);
      })
  }

  // Dashboard Statistics APIs
  const getRevenue = () => {
    Http.callApi(url.get_dashboard_revenue)
      .then(function (res) {
        console.log(res.data);
        setRevenue(res.data.revenue);
      }).catch(function (err) {
        console.log(err);
      });
  }

  const getAverageOrderValue = () => {
    Http.callApi(url.get_average_order_value)
      .then(function (res) {
        console.log(res.data);
        setAverageValue(res.data.average_order_value);
      }).catch(function (err) {
        console.log(err);
      });
  }

  const getAllTimeRefunds = () => {
    Http.callApi(url.get_all_time_refunds)
      .then(function (res) {
        console.log(res.data);
        setTotalRefunds(res.data.refunded_value);
      }).catch(function (err) {
        console.log(err);
      });
  }

  const getActiveVisits = () => {
    Http.callApi(url.get_active_visits)
      .then(function (res) {
        console.log(res.data);
        setActiveVisits(res.data.active_visits);
      }).catch(function (err) {
        console.log(err);
      });
  }

  const getCartAbandonmentValue = () => {
    Http.callApi(url.get_cart_abandonment_value)
      .then(function (res) {
        console.log(res.data);
        setCartAbandonment(res.data.cart_abandonment);
      }).catch(function (err) {
        console.log(err);
      });
  }

  const getOrderFrequency = () => {
    Http.callApi(url.get_order_frequency)
      .then(function (res) {
        console.log(res.data);
        setOrderFrequency(res.data.average_difference);
      }).catch(function (err) {
        console.log(err);
      });
  }

  return (
    <>
      <Row className="g-3 mb-3">
        <button onClick={() => {
          navigation('/stream-controller', { replace: true });
        }} className='btn-default w-100' style={{ backgroundColor: "blue", border: '0px', color: "white", height: 55, borderRadius: '12px' }}>Control Opal Live Stream</button>
        <Col md={3} xxl={3}>
          <WeeklySales data={{ title: "Total Users", total: users, icon: ['fas', 'users'] }} />
        </Col>
        <Col md={3} xxl={3}>
          <WeeklySales data={{ title: "Total Vendors", total: vendors, icon: ['fas', 'users'] }} />
        </Col><Col md={3} xxl={3}>
          <WeeklySales data={{ title: "Total Items", total: items, icon: "shopping-cart" }} />
        </Col><Col md={3} xxl={3}>
          <WeeklySales data={{ title: "Total Orders", total: orders, icon: "cart-plus" }} />
        </Col>
        <StatisticsCards data={statisticsData} />

      </Row>

      <Row className="g-3 mb-3">
        {/* <Col lg={12} xl={12}> */}
        {/* <BestSellingProducts products={itemsData} /> */}
        {/* </Col> */}
      </Row>
    </>
  );
};

export default Dashboard;
