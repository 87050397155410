import url from './../../Development.json';
import Http from '../../security/Http'
import * as action from '../../store/actions'
import ToastMe from '../../components/common/ToastMe';
import { successResponse } from 'components/helpers/response';

const ExersiseService = {

    getData: () => {
        return dispatch => (
            new Promise((resolve, reject) => {
                Http.callApi(url.get_support)
                    .then(function (res) {
                        return resolve(res);
                    })
                    .catch(function (err) {
                        // handle error
                        let errorData, statusCode
                        if (err.response != undefined) {
                            errorData = err?.response?.data?.errors
                            statusCode = err?.response?.status
                        }
                        return reject({
                            errorData,
                            statusCode
                        });
                    })

            })
        )
    },

  

    // delete category
    deleteexersise: (data) => {
        return dispatch => (
            new Promise((resolve, reject) => {
                Http.callApi(url.delete_exersise, data)
                    .then(function (res) {
                        return resolve(res);
                    })
                    .catch(function (err) {
                        const statusCode = err.response.status;
                        const data = {
                            errors: err.response.data.errors,
                            statusCode,
                            data: err.response.data
                        };
                        return reject(data);
                    })

            })
        )

    }
}

export default ExersiseService;