
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Modal, Row, Tooltip } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import GetImage from '../image/getImage';

const Imagee = (props) => {
    return (
        <>
            <div className='row'>
                <div className='col-6 flex-nowrap'>
                    <div className='item_detail_img_container' >
                        <img src={props.dataImage} alt="user" />
                    </div>
                </div>
            </div>
        </>
    );
};

const SubCategory = () => {
    const { state } = useLocation();
    const [data, setData] = useState([]);

    useEffect(() => {
        if (state && state.data) {
            setData(JSON.parse(state.data));
        }
    }, []);

    return (
        <>
            <div style={{ borderRadius: "0.375rem" }} className='py-4 bg-white mb-3 d-flex align-items-center px-3'>
                <h5 className="hover-actions-trigger mb-0">
                    Item / Auction Details
                </h5>
            </div>
            <div className='col-lg-8 mx-auto'>
                <Card className='p-4'>
                    <div className='row row-cols-1 g-3'>
                        <div className='col d-flex justify-content-center'>
                            {data?.image?.map((itemImage, i) => {
                                return <Imagee key={i} dataImage={itemImage} />
                            })}
                        </div>
                        <div className='col'>
                            <hr className='w-100 bg-primary my-2' />
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Name</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.name ?? '-'}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Description</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.description ?? '-'}</div>
                            </div>
                        </div>

                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Price</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.price ?? '-'}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Depth</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.depth ?? '-'}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Type</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.is_auction === 0 ? 'Item' : 'Auction'}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Is Active</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>
                                    <span className={`btn-sm   ${data.is_active ? "btn-success" : "btn-danger"}`}>
                                        {
                                            data.is_active ? "Yes" : "No"
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>length</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.length ?? '-'}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Weight</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.weight ?? '-'}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Width</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.width ?? '-'}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Shape</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.shape?.name ?? "-"}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Type</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.type?.name ?? "-"}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>is_filp_coin</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>
                                    <span className={`btn-sm   ${data.is_filp_coin ? "btn-success" : "btn-danger"}`}>
                                        {
                                            data.is_filp_coin ? "Yes" : "No"
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        {data.is_filp_coin === true ? <> <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>max_price</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.max_price ?? '-'}</div>
                            </div>
                        </div>
                            <div className='col'>
                                <div className='row'>
                                    <div className='col-4 user_view_label'>min_price</div>
                                    <div className="col-2">:</div>
                                    <div className='col-6 flex-nowrap'>{data?.min_price ?? '-'}</div>
                                </div>
                            </div></> : ''}

                    </div>
                </Card>
            </div>
        </>
    );
};

// export default Interest;
const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        accessToken: state.Auth.accessToken,
    }
};
export default connect(mapStateToProps)(SubCategory);
