import url from './../../Development.json';
import Http from '../../security/Http'
import * as action from '../../store/actions'
import ToastMe from '../../components/common/ToastMe';
import { successResponse } from 'components/helpers/response';
// const BaseUrl = process.env.REACT_APP_API_HOST;

const ItemTypeService = {

    getData: (data) => {
        return dispatch => (

            new Promise((resolve, reject) => {
                Http.callApi(url.get_auction_shape)
                    .then(function (res) {
                        return resolve(res);
                    })
                    .catch(function (err) {
                        // handle error
                        let errorData, statusCode
                        if (err.response != undefined) {
                            errorData = err?.response?.data?.errors
                            statusCode = err?.response?.status
                        }
                        return reject({
                            errorData,
                            statusCode
                        });
                    })

            })
        )
    },

    // add shape
    addShape: (data) => {
        return dispatch => (
            new Promise((resolve, reject) => {

                var urlInfo = ""
                var dataInfo = {};
                if (data.id != "") {
                    urlInfo = url.update_auction_shape
                    dataInfo = {
                        'name': data.name,
                        '_id':data.id
                    }
                } else {
                    urlInfo = url.add_auction_shape
                    dataInfo = {
                        'name': data.name,
                    }
                }

                
                Http.callApi(urlInfo, dataInfo)
                    .then(function (res) {
                        // successResponse(res)
                        return resolve(res);
                    })
                    .catch(function (err) {
                        ToastMe(err.response.data.message, 'danger');

                        const statusCode = err.response.status;
                        const data = {
                            errors: err.response.data.errors,
                            statusCode,
                            data: err.response.data
                        };
                        return reject({ data });
                    })

            })
        )
    },


    // delete Shape
    deleteShape: (data) => {
        return dispatch => (
            new Promise((resolve, reject) => {
                Http.callApi(url.delete_auction_shape, data)
                    .then(function (res) {
                        return resolve(res);
                    })
                    .catch(function (err) {
                        const statusCode = err.response.status;
                        const data = {
                            errors: err.response.data.errors,
                            statusCode,
                            data: err.response.data
                        };
                        return reject(data);
                    })

            })
        )

    }
}

export default ItemTypeService;