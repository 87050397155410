import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Modal, Row, Tooltip } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';
import { Dropdown } from 'react-bootstrap';
import url from './../../Development.json';
import Http from '../../security/Http'
import { useForm } from "react-hook-form";
import { errorResponse, successResponse, isError } from "../helpers/response";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import GetImage from '../image/getImage';

const Imagee = (props) => {
    const [imgSrc, setImageSrc] = useState("");
    const ImageUrl = (datas) => {
        setImageSrc(datas);
    }
    return (
        <>
            <GetImage im={props.dataImage} ImageData={ImageUrl} />
            <div className="mt-3 col-lg-2">
                <Flex
                    alignItems="center"
                    className="py-3 border-bottom btn-reveal-trigger"
                    key={imgSrc}
                >
                    <img rounded width={90} height={90} src={imgSrc} alt="Image" />
                    <CardDropdown>
                        <div className="py-2">
                            <Dropdown.Item className="text-danger"
                                onClick={() => props.imgRemoveFun(props.dataImage)}
                            > Remove </Dropdown.Item>
                        </div>
                    </CardDropdown>
                </Flex>

            </div>
        </>
    );
};

const AddItem = () => {
    const [files, setFiles] = React.useState([]);
    const [getShape, setGetShape] = useState([]);
    const [getType, setGetType] = useState([]);
    const [images, setImage] = useState([]);
    const [deleteImgs, setDeleteImgs] = useState([]);
    const [deleteImgsGet, setDeleteImgsGet] = useState([]);

    const [coin, setCoin] = useState("0");
    const navigate = useNavigate();

    const { setValue, handleSubmit, register, formState: { errors }, reset } = useForm();
    const { state } = useLocation();
    const [data, setData] = useState([]);
    const [imageGet, setImageGet] = useState([]);

    useEffect(() => {
        if (state && state.data) {
            setData(JSON.parse(state.data));
            setValue('name', data?.name);
            setValue('description', data?.description);
            setValue('weight', data?.weight);
            setValue('width', data?.width);
            setValue('length', data?.length);
            setValue('depth', data?.depth);
            setValue('price', data?.price);
            setValue('shape', JSON.stringify(data?.shape));
            setValue('type', JSON.stringify(data?.type));
            setImageGet(data?.image);
            data.is_filp_coin ? setCoin("1") : setCoin("0")
            setValue('is_filp_coin', data.is_filp_coin ? "1" : "0");
            setValue('max_price', data?.max_price);
            setValue('min_price', data?.min_price);
        }
    }, [data?._id]);

    const getTypepeData = () => {
        Http.callApi(url.get_item_type)
            .then((response) => {
                setGetType(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getShapeData = () => {
        Http.callApi(url.get_item_shape)
            .then((response) => {
                setGetShape(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const imageUpload = (data) => {
        data.map((item) => {
            let img = new FormData();
            img.append('image', item);

            Http.callApi(url.upload_image, img)
                .then(function (res) {
                    images.push({ img: res.data.image, data: item });
                })
                .catch(function (err) {
                    // handle error
                    console.log(err);
                })
        })
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            imageUpload(acceptedFiles)

            acceptedFiles.map(file =>
                files.push(Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            )
        }
    });
    const addItem = (itemData) => {
        let img = [];
        if (images.length != 0) {
            images.map((item) => {
                img.push(item.img);
            })
        }
        let imgDel = [];
        if (deleteImgs.length != 0) {
            deleteImgs.map((item) => {
                imgDel.push(item[0].img);
            })
        }
        itemData['is_filp_coin'] = (coin != "0") ? true : false;
        if (coin == "0") {
            itemData['max_price'] = 0;
            itemData['min_price'] = 0;
        }
        itemData['_id'] = data._id;
        itemData['image'] = img;
        itemData['delete_image'] = imgDel.concat(deleteImgsGet);
        itemData['type'] = JSON.parse(itemData['type']);
        itemData['select_item_type'] = '2';
        
        itemData['shape'] = JSON.parse(itemData['shape']);
        if (img.concat(imageGet).length == 0) {
            toast.error("Image is required.");
        } else {
            Http.callApi(url.update_item_action, itemData)
                .then(function (res) {
                    toast.success("Item update successfully.");
                    setTimeout(() => {
                        navigate('/item-auction');
                    }, 2500);
                })
                .catch(function (error) {
                    // handle error
                    if (error.response.data.errors) {
                        let arr = error.response.data.errors;
                        Object.values(arr).map((item) => {
                            toast.error(item);
                        })
                    }
                    if (!error) {
                        toast.error("Please Try Again!");
                    }
                })
        }
    }
    const handleRemove = path => {
        setFiles(files.filter(file => file.path !== path));
        setImage(images.filter(data => data.data.path !== path))
        deleteImgs.push(images.filter(data => data.data.path == path));
    };
    const ImageUrlHandleRemove = (datas) => {
        setImageGet(imageGet.filter(data => data !== datas));
        deleteImgsGet.push(datas);
    }

    useEffect(() => {
        isError(errors);
    });

    useEffect(() => {
        getShapeData();
        getTypepeData();
    }, []);

    return (
        <>
            <div style={{ borderRadius: "0.375rem" }} className='py-4 bg-white mb-3 d-flex align-items-center px-3'>
                <h5 className="hover-actions-trigger mb-0">
                    Update Item
                </h5>
            </div>

            <Form noValidate onSubmit={handleSubmit(addItem)}>
                <div className='row'>
                    <Form.Group className="mb-3 col-lg-6">
                        <Form.Select aria-label="Default select example" name="type" id="type" isInvalid={!!errors.type}
                            {...register('type', {
                                required: true
                            })} >
                            <option value='' disabled selected>Select item type</option>
                            {getType.map((item, index) => {
                                return (
                                    <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-6">
                        <Form.Select aria-label="Default select example" name="shape" id="shape" isInvalid={!!errors.shape}
                            {...register('shape', {
                                required: true
                            })}>
                            <option value='' disabled >Select item shape</option>
                            {getShape.map((item, index) => {
                                return (
                                    <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                </div>
                <div className='row'>
                    <Form.Group className="mb-3 col-lg-6" >
                        <Form.Label>Item Name</Form.Label>
                        <Form.Control type="text" name="name" id="name" placeholder="Enter item name" isInvalid={!!errors.name}
                            {...register('name', {
                                required: true
                            })} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-6">
                        <Form.Label>Item Price</Form.Label>
                        <Form.Control type="text" name="price" id="price" placeholder="Enter item price" isInvalid={!!errors.price}
                            {...register('price', {
                                required: true,
                                pattern: {
                                    value: /^\d+(?:\.\d+)?$/,
                                    message: 'Please enter a number',
                                },
                            })} />
                    </Form.Group>
                </div>
                <div className='row'>
                    <Form.Group className="mb-3" >
                        <Form.Label>Item Description</Form.Label>
                        <Form.Control as="textarea" rows={3} id="description" name="description" isInvalid={!!errors.description}
                            {...register('description', { required: true })} />
                    </Form.Group>
                </div>
                <div className='row'>
                    <Form.Group className="mb-3 col-lg-3">
                        <Form.Label>Item Weight</Form.Label>
                        <Form.Control type="text" name="weight" id="weight" placeholder="weight"
                            {...register('weight', {
                                required: true,
                                pattern: {
                                    value: /^\d+(?:\.\d+)?$/,
                                    message: 'Please enter a number',
                                },
                            })} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-3">
                        <Form.Label>Item Width</Form.Label>
                        <Form.Control type="text" name="width" id="width" placeholder="width (mm)"
                            {...register('width', {
                                required: true,
                                pattern: {
                                    value: /^\d+(?:\.\d+)?$/,
                                    message: 'Please enter a number',
                                },
                            })} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-3">
                        <Form.Label>Item length</Form.Label>
                        <Form.Control type="text" name="length" id="length" placeholder="length (mm)"
                            {...register('length', {
                                required: true,
                                pattern: {
                                    value: /^\d+(?:\.\d+)?$/,
                                    message: 'Please enter a number',
                                },
                            })} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-3">
                        <Form.Label>Item Depth</Form.Label>
                        <Form.Control type="text" name="depth" id="depth" placeholder="depth (mm)"
                            {...register('depth', {
                                required: true,
                                pattern: {
                                    value: /^\d+(?:\.\d+)?$/,
                                    message: 'Please enter a number',
                                },
                            })} />
                    </Form.Group>
                </div>
                <div className='row'>
                    <Form.Group className="mb-3 col-lg-4" >
                        <Form.Label> Filp Coin</Form.Label>
                        <Form.Select
                            name="is_filp_coin"
                            id="is_filp_coin"
                            value={coin}
                            aria-label="Default select example"
                            onChange={(e) => setCoin(e.target.value)}
                        >
                            <option value="1">ON</option>
                            <option value="0">OFF</option>
                        </Form.Select>
                    </Form.Group>

                    {coin == "1" ? <><Form.Group className="mb-3 col-lg-4">
                        <Form.Label>Max Price</Form.Label>
                        <Form.Control type="text" name="max_price" id="max_price" placeholder="Max price"
                            {...register('max_price', {
                                required: true,
                                pattern: {
                                    value: /^\d+(?:\.\d+)?$/,
                                    message: 'Please enter a number',
                                },
                            })} />
                    </Form.Group>
                        <Form.Group className="mb-3 col-lg-4">
                            <Form.Label>Min Price</Form.Label>
                            <Form.Control type="text" name="min_price" id="min_price" placeholder="Min price"
                                {...register('min_price', {
                                    required: true,
                                    pattern: {
                                        value: /^\d+(?:\.\d+)?$/,
                                        message: 'Please enter a number',
                                    },
                                })} />
                        </Form.Group></> : <></>}
                </div>
                <div {...getRootProps({ className: 'dropzone-area py-4' })}>
                    <input {...getInputProps()} />
                    <Flex justifyContent="center">
                        <img src={cloudUpload} alt="" width={25} className="me-2" />
                        <p className="fs-0 mb-0 text-700">Drop your images here</p>
                    </Flex>
                </div>
                <div className='row'>
                    {files.map(file => (
                        <div className="mt-3 col-lg-2">

                            <Flex
                                alignItems="center"
                                className="py-3 border-bottom btn-reveal-trigger"
                                key={file.path}
                            >
                                <img rounded width={90} height={90} src={file.preview} alt={file.path} />
                                <CardDropdown>
                                    <div className="py-2">
                                        <Dropdown.Item className="text-danger" onClick={() => handleRemove(file.path)}>
                                            Remove
                                        </Dropdown.Item>
                                    </div>
                                </CardDropdown>
                            </Flex>

                        </div>
                    ))}
                    {imageGet?.map((itemImage, i) => {
                        return <Imagee key={i} dataImage={itemImage} imgRemoveFun={ImageUrlHandleRemove} />
                    })}

                </div>
                <br></br>
                <Button variant="primary" type="submit">Submit</Button>

            </Form>

        </>
    );
};

// export default Interest;
const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        accessToken: state.Auth.accessToken,
    }
};
export default connect(mapStateToProps)(AddItem);
