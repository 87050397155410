
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Modal, Row, Tooltip } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import GetImage from '../image/getImage';

const SubCategory = () => {
    const { state } = useLocation();
    const [data, setData] = useState([]);
    const [imgSrc, setImageSrc] = useState("");
    const ImageUrl = (datas) => {
        setImageSrc(datas);
    }

    useEffect(() => {
        if (state && state.data) {
            setData(JSON.parse(state.data));
        }
        ImageUrl();
    }, []);

    return (
        <>
            <div style={{ borderRadius: "0.375rem" }} className='py-4 bg-white mb-3 d-flex align-items-center px-3'>
                <h5 className="hover-actions-trigger mb-0">
                    Support Details
                </h5>
            </div>

            <div className='col-lg-8 mx-auto'>
                <Card className='p-4'>
                    <div className='row row-cols-1 g-3'>
                        <div className='col'>
                            <hr className='w-100 bg-primary my-2' />
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Name</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.name}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Email</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.email ?? '-'}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Mobile</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.mobile ?? '-'}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Subject</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.subject ?? '-'}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>resolved</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.is_resolved === 0 ? "No" : "yes"}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4 user_view_label'>Message</div>
                                <div className="col-2">:</div>
                                <div className='col-6 flex-nowrap'>{data?.message ?? '-'}</div>
                            </div>
                        </div>
                        <div className='col'>
                            <hr className='w-100 bg-primary my-2' />
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

// export default Interest;
const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        accessToken: state.Auth.accessToken,
    }
};
export default connect(mapStateToProps)(SubCategory);
