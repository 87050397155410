import React from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row, Table } from 'react-bootstrap';

const OrderedProducts = ({ details }) => {

  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Products</th>
                <th className="border-0 text-center">Quantity</th>
                <th className="border-0 text-end">Amount</th>
              </tr>
            </thead>
            <tbody>
              {details.items ? details.items.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle">
                    <h6 className="mb-0 text-nowrap">{product.name}</h6>
                    <p className="mb-0">{product.desc}</p>
                  </td>
                  <td className="align-middle text-center">
                    {product.quantity}
                  </td>
                  <td className="align-middle text-end">
                    ${product.price.toFixed(2)}
                  </td>
                </tr>
              )) : null}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  <tr>
                    <th className="text-900">Subtotal:</th>
                    <td className="fw-semi-bold">
                      ${details ? details.sub_total : ""}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-900">GST (Included):</th>
                    <td className="fw-semi-bold">
                      ${details ? details.gst : ""}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-900">Delivery Fee (Included):</th>
                    <td className="fw-semi-bold">
                      ${details.delivery_fee ? details.delivery_fee.toFixed(2) : ""}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-900">Payment Processing Fee (Included):</th>
                    <td className="fw-semi-bold">
                      ${details ? details.processing_fee : ""}
                    </td>
                  </tr>
                  <tr className="border-top">
                    <th className="text-900">Total:</th>
                    <td className="fw-semi-bold">
                      ${details ? details.total : ""}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

OrderedProducts.propTypes = {};

export default OrderedProducts;
