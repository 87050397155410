import React, { useEffect, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { orderList } from 'data/ecommerce/orderList';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import OrdersTableHeader from './OrdersTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import Http from 'security/Http';
import url from '../../../../../Development.json';
import { errorResponse } from 'components/helpers/response';

const columns = [
  {
    accessor: 'name',
    Header: 'Order',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { order_no, customer } = rowData.row.original;
      return (
        <>
          <Link to={`/orders/${order_no}`}>
            <strong>#{order_no}</strong>
          </Link>{' '}
          by <strong>{customer.first_name} {customer.last_name}</strong> <br />
          <a href={`mailto:${customer.email}`}>{customer.email}</a>
        </>
      );
    }
  },
  {
    accessor: 'order_date',
    Header: 'Date',
    headerProps: { className: 'pe-7' },
    Cell: rowData => {
      const { order_date } = rowData.row.original;
      const currentDateTime = new Date(order_date);
      return (
        <>
          {currentDateTime.toLocaleDateString()}
        </>
      );
    }
  },
  {
    accessor: 'address',
    Header: 'Ship To',
    Cell: rowData => {
      const { addresses, delivery_fee } = rowData.row.original;
      return (
        <>
          {addresses.address}
          <p className="mb-0 text-500">Australia Post (${delivery_fee ? delivery_fee.toFixed(2) : "0.00"})</p>
        </>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: {
      className: 'text-center'
    },
    cellProps: {
      className: 'fs-0'
    },
    Cell: rowData => {
      const { order_status } = rowData.row.original;
      return (
        <SoftBadge
          pill
          bg={classNames({
            success: order_status === '2',
            primary: order_status === '1',
            warning: order_status === '0',
            secondary: order_status === '0'
          })}
          className="d-block"
        >
          {order_status == "0" ? "Awaiting Vendor Acceptance" : order_status == "1" ? "Preparing" : "Shipped"}
          <FontAwesomeIcon
            icon={classNames({
              check: order_status === '2',
              stream: order_status === '0',
              ban: order_status === '0'
            })}
            transform="shrink-2"
            className="ms-1"
          />
        </SoftBadge>
      );
    }
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end fs-0 fw-medium py-2'
    },
    Cell: rowData => `$${rowData.row.original.total} AUD`
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: () => {
      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item href="#!">Completed</Dropdown.Item>
            <Dropdown.Item href="#!">Processing</Dropdown.Item>
            <Dropdown.Item href="#!">On Hold</Dropdown.Item>
            <Dropdown.Item href="#!">Pending</Dropdown.Item>
            <Dropdown.Divider as="div" />
            <Dropdown.Item href="#!" className="text-danger">
              Delete
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const Orders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = () => {
    Http.callApi(url.get_all_orders)
      .then((response) => {
        console.log(response);
        setOrders(response.data);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orders}
      selection
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <OrdersTableHeader table />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default Orders;
