import Swal from 'sweetalert2';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Modal, Row, Toast, ToastContainer, Tooltip } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlayCircle, faTrashAlt, faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'components/common/IconButton';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { useForm } from "react-hook-form";
import { errorResponse, isError } from 'components/helpers/response';
import { toast } from 'react-toastify';
import podcastService from '../../services/podcast/podcast';
import url from './../../Development.json';
import Http from '../../security/Http'
import GetImage from '../image/getImage';

const SubCategory = () => {
    const dispatch = useDispatch();
    const [dataTableData, setDataTableData] = useState([]);
    const [show, setShow] = useState(false);
    const [valueCategory, setCategory] = useState([]);
    const [showAudio, setShowAudio] = useState(false);
    const [playAudio, setPlayAudio] = useState("");

    const handleClose = () => {
        reset(
            { keepDirtyValues: true },
            { keepIsValid: true }
        );
        setShow(false)
    };
    const {
        setValue,
        handleSubmit,
        register,
        formState: { errors },
        reset
    } = useForm();

    useEffect(() => {
        getData();
        getMainCategoryData();
    }, []);

    useEffect(() => {
        isError(errors);
    });
    const handleShow = (data) => {
        setShow(true)
        setValue('title', data.title)
        setValue('image', data.image)
        setValue('description', data.description)
    };

    const getData = () => {
        dispatch(podcastService.getData())
            .then((res) => {
                setDataTableData(res.data);
            })
            .catch((errors) => {
                console.log(errors);
            });
    }

    const getMainCategoryData = () => {
        dispatch(podcastService.getMainCategory())
            .then((res) => {
                setCategory(res?.data);
            })
            .catch((errors) => {
                console.log(errors);
            })
    }

    const addPodcast = async (data) => {

        data['play_duration'] = "60";

        console.log(data['file'][0]);

        const file = new FormData();
        file.append('file_name', data['file'][0])
        // audio upload
        await Http.callApi(url.upload_audio, file)
            .then(function (res) {
                data['file'] = res.data.file_name;
            })
            .catch(function (err) {
                // handle error
                toast.error(err?.data?.message);
            })

        // image upload
        const image = new FormData();
        image.append('file_name', data['image'][0]);

        await Http.callApi(url.upload_audio_image, image)
            .then(function (res) {
                data['image'] = res.data.file_name;
            })
            .catch(function (err) {
                // handle error
                console.log(err);
                toast.error(err?.data?.message);
            })

        await dispatch(podcastService.addPodcast(data))
            .then((res) => {
                getData();
                handleClose();
                setValue('title', '');
                setValue('description', '');
            })
            .catch((errors) => {
                toast.error(errors.data.message);
            })
    }
    const handleTosterShow = (event) => {
        setPlayAudio(event)
        setShowAudio(true)
    }
    const handleTosterClose = () => {
        setPlayAudio("")
        setShowAudio(false)
    }
    // delete podcast
    const deleteCategory = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to restore this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                var data = {
                    id: id,
                }
                dispatch(podcastService.deletePodcast(data))
                    .then((res) => {
                        toast.success(res.data.message);
                        getData();
                    })
                    .catch((errors) => {
                        console.log(errors);
                    })
            }
        })
    }
    const columns = [
        {
            accessor: '_id',
            Header: 'No',
            Cell: rowData => {
                return (parseInt(rowData.row.id) + 1)
            }
        },
        {
            accessor: 'image',
            Header: "Thumbnail",
            Cell: rowData => {
                const row = rowData.row.original
                return (<>
                    <img style={{ height: 100, width: 'auto' }} src={row.image}></img>
                </>)
            }
        },
        {
            accessor: 'title',
            Header: 'Title'
        },
        {
            accessor: 'description',
            Header: 'Description',

            Cell: rowData => {
                const row = rowData.row.original
                return (<>
                    <div style={{ whiteSpace: 'normal', maxWidth: '400px' }} >
                        {row.description}
                    </div>
                </>)
            }
        },
        // {
        //     accessor: 'image',
        //     Header: 'Image',
        //     Cell: rowData => {
        //         const data = rowData.row.original
        //         const [imgSrc, setImageSrc] = useState("");
        //         const ImageUrl = (datas) => {
        //             setImageSrc(datas);
        //         }
        //         return (
        //             <>
        //                 <GetImage im={data.image ?? ''} ImageData={ImageUrl} />
        //                 <img src={imgSrc} className="profile_pic_img" style={{ "height": "80px", "width": "80px", "borderRadius": "50" }} />
        //             </>
        //         )
        //     }
        // },
        {
            accessor: 'file',
            Header: 'File',
            Cell: rowData => {
                const data = rowData.row.original
                const audio = data.file;
                return (
                    <>
                        <div className='t-action-btn'>
                            {playAudio !== audio ? <button title='Play audio' className="btn btn-primary" onClick={() => handleTosterShow(audio)}><FontAwesomeIcon icon={faPlayCircle} /></button> : <button className="btn btn-primary" onClick={() => handleTosterShow(audio)}><FontAwesomeIcon icon={faPauseCircle} /></button>}
                        </div>
                    </>
                )
            }
        },
        {
            accessor: 'createdAt',
            Header: 'Action',
            Cell: rowData => {
                const row = rowData.row.original
                return (
                    <>
                        <div className='t-action-btn'>

                            <button className="btn btn-danger" title='Delete Podcast' onClick={(e) => deleteCategory(row._id)} >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>

                        </div>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={dataTableData}
                sortable
                pagination
                perPage={10}
            >
                <div style={{ borderRadius: "0.375rem" }} className='py-4 bg-white mb-3 d-flex align-items-center px-3'>
                    <h5 className="hover-actions-trigger mb-0">
                        Podcasts
                    </h5>
                </div>
                <Card className='mb-3'>

                    <Card.Header className="border-bottom border-200">
                        <Row className="flex-between-center align-items-end g-2">
                            <Col xs={8} sm="auto" className="ms-3 mt-2 text-end ps-0">
                                <div id="orders-actions">
                                    <IconButton onClick={handleShow}
                                        icon="plus"
                                        transform="shrink-3"
                                        className='btn btn-primary me-2'
                                    >
                                        <span className="d-none d-sm-inline-block ms-1">Add</span>
                                    </IconButton>
                                </div>
                            </Col>
                            <Col xs="auto" sm={6} lg={4}>
                                <AdvanceTableSearchBox table />
                            </Col>
                        </Row>
                    </Card.Header>
                    <Row className="flex-end-center mb-3">

                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Row>
                </Card>

                <div className="mt-3">
                    <AdvanceTableFooter
                        rowCount={dataTableData.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableWrapper>
            <ToastContainer className="p-3 position-fixed" style={{ zIndex: "99" }} position={"bottom-end"}>
                <Toast onClose={handleTosterClose} show={showAudio} delay={3000} >
                    <Toast.Body><Toast.Header className='border-0 p-0 pe-2'><audio src={playAudio} controls autoPlay preload='auto' /></Toast.Header></Toast.Body>
                </Toast>
            </ToastContainer>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Add a Podcast</Modal.Title>
                    <FalconCloseButton onClick={handleClose} />
                </Modal.Header>
                <Form onSubmit={handleSubmit(addPodcast)} >
                    <Modal.Body>
                        <Form.Group className="mb-3" >
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" name="title" id="title" placeholder="Enter Title name" isInvalid={!!errors.name}
                                {...register('title', { required: 'Title is required', })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.title && errors.title.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} name="description" id="description" placeholder="Description....." isInvalid={!!errors.name}
                                {...register('description', { required: 'Description is required', })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.description && errors.description.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file" name="image" id="image" accept=".png, .jpg, .jpeg" isInvalid={!!errors.name}
                                {...register('image', { required: 'image is required', })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.image && errors.image.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Podcast File</Form.Label>
                            <Form.Control type="file" name="file" id="file" accept=".mp3, .mp4, .mov" isInvalid={!!errors.name}
                                {...register('file')}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.audio && errors.audio.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">Submit</Button>
                        {/* onClick={() => setValue(() => "")} */}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

// export default Interest;
const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        accessToken: state.Auth.accessToken,
    }
};
export default connect(mapStateToProps)(SubCategory);
